import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {
  provideHttpClient,
  withFetch,
  withInterceptors,
} from '@angular/common/http';
import { provideToastr } from 'ngx-toastr';
import {
  MAT_DATE_LOCALE,
  provideNativeDateAdapter,
} from '@angular/material/core';
import { provideNgxMask } from 'ngx-mask';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { provideIcons } from '@ng-icons/core';
import {
  heroArchiveBoxXMark,
  heroArrowRight,
  heroCalendar,
  heroCheck,
  heroCheckCircle,
  heroChevronRight,
  heroChevronLeft,
  heroClipboard,
  heroClipboardDocumentCheck,
  heroClock,
  heroCurrencyDollar,
  heroEye,
  heroLink,
  heroMagnifyingGlass,
  heroMinusCircle,
  heroPencil,
  heroPlus,
  heroTicket,
  heroTrash,
  heroXMark,
  heroXCircle,
  heroClipboardDocument,
  heroChartBar,
} from '@ng-icons/heroicons/outline';
import {
  heroBellAlertSolid,
  heroCloudArrowUpSolid,
  heroCheckCircleSolid,
  heroXCircleSolid,
  heroExclamationCircleSolid,
  heroMinusCircleSolid,
  heroClockSolid,
  heroBellSlashSolid
} from '@ng-icons/heroicons/solid';
import { heroArchiveBoxXMarkMini } from '@ng-icons/heroicons/mini';
import { authInterceptor } from './interceptor/auth.interceptor';
import { OWL_DATE_TIME_LOCALE, OwlDateTimeIntl } from '@danielmoncada/angular-datetime-picker';
import { DefaultIntl } from './helpers/DefaultIntlDateTimePicker';

export const appConfig: ApplicationConfig = {
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' },
    },
    provideRouter(routes),
    provideClientHydration(),
    provideAnimationsAsync(),
    provideHttpClient(withFetch(), withInterceptors([authInterceptor])),
    provideToastr(),
    provideNativeDateAdapter(),
    provideNgxMask(),
    provideIcons({
      heroChevronRight,
      heroChevronLeft,
      heroCloudArrowUpSolid,
      heroEye,
      heroPencil,
      heroTrash,
      heroCheck,
      heroXMark,
      heroPlus,
      heroArchiveBoxXMarkMini,
      heroMagnifyingGlass,
      heroTicket,
      heroBellAlertSolid,
      heroCheckCircle,
      heroMinusCircle,
      heroArchiveBoxXMark,
      heroClipboardDocumentCheck,
      heroClock,
      heroCurrencyDollar,
      heroClipboard,
      heroLink,
      heroCalendar,
      heroArrowRight,
      heroXCircle,
      heroCheckCircleSolid,
      heroXCircleSolid,
      heroExclamationCircleSolid,
      heroMinusCircleSolid,
      heroClockSolid,
      heroClipboardDocument,
      heroBellSlashSolid,
      heroChartBar
    }),
    {provide: OWL_DATE_TIME_LOCALE, useValue: 'pt-BR'},
  ],
};
