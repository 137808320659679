import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDrawer } from '@angular/material/sidenav';
import { environment } from '../../environments/environment';
import * as jwt_decode from 'jwt-decode';
import { DOCUMENT } from '@angular/common';
import shoppingCodes from './shopping_codes';
import { defaultColors } from '../../commons/defaultColors';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  URL_BASE = environment.apiUrl;
  URL_LOGIN = environment.urlLogin;
  URL_USER = environment.urlUser;
  URL_REFRESH = environment.urlRefreshToken;
  URL_RESET_PASSWORD = environment.urlResetPassword;
  SHOPPING_ID = '{shopping_id}';

  public token = new BehaviorSubject<any>(undefined);
  public token$ = this.token.asObservable();

  public refresh_token = new BehaviorSubject<any>(undefined);
  public refresh_token$ = this.refresh_token.asObservable();
  
  public floor_list = new BehaviorSubject<any>(undefined);
  public floor_list$ = this.floor_list.asObservable();

  public color_scheme = new BehaviorSubject<any>(defaultColors);
  public color_scheme$ = this.color_scheme.asObservable();

  public services_list = new BehaviorSubject<any>(undefined);
  public services_list$ = this.services_list.asObservable();

  // public userId = new BehaviorSubject<any>(undefined);
  // public userId$ = this.userId.asObservable();

  public sidenav: any;
  private localstorage: any;

  constructor(
    private http: HttpClient,
    private _router: Router,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.localstorage = document.defaultView?.localStorage;

    if (this.localstorage) {
      const counter = this.localstorage?.getItem('counter');

      if (counter) {
        this.localstorage.setItem(
          'counter',
          (parseInt(counter) + 1).toString()
        );
      } else {
        this.localstorage.setItem('counter', '1');
      }
    }
  }

  login(body: any) {
    return this.http.post(
      this.URL_BASE + this.SHOPPING_ID + this.URL_LOGIN,
      body
    );
  }
  changePassword(password: string, id?: string) {
    const user_id = this.getDecodedAccessToken().user;
    return this.http.put(
      this.URL_BASE +
        this.SHOPPING_ID +
        this.URL_LOGIN +
        this.URL_RESET_PASSWORD +
        '/' +
        (id ?? user_id),
      { password: password }
    );
  }

  getUserInfo() {
    const user_id = this.getDecodedAccessToken().user;
    return this.http.get(
      this.URL_BASE + this.SHOPPING_ID + this.URL_USER + '/' + user_id
    );
  }
  logout() {
    this._router.navigate([this.localstorage?.getItem('shopping_code') ?? this._router.url,'login']);
    this.localstorage?.clear();
    this.token.next(undefined);
    this.color_scheme.next(defaultColors);
    this.floor_list.next([]);
    this.services_list.next([]);
  }
  refreshToken() {
    const user_id = this.getDecodedAccessToken().user;
    return this.http.post(
      this.URL_BASE + 1 + this.URL_USER + '/' + user_id + this.URL_REFRESH,
      { token: this.localstorage?.getItem('token') }
    );
  }
  saveToken(token: any) {
    this.localstorage?.setItem('token', token);
    this.token.next(token);
  }
  saveLoginItems(res: any) {
    this.localstorage?.setItem('token', res.token);
    this.localstorage?.setItem('refresh_token', res.refresh_token);
    this.token.next(res.token);
    this.localstorage?.setItem('color_scheme', res.color_scheme);
    this.localstorage?.setItem('floor_list', res.floor_list);
    this.localstorage?.setItem('provider_shopping_id', res.location_provider?.provider_shopping_id);
    this.localstorage?.setItem('provider_label', res.location_provider?.provider_label);
  }
  saveShoppingConfig(info: any){
    this.localstorage?.setItem('color_scheme', JSON.stringify(info.color_scheme));
    this.localstorage?.setItem('floor_list', info.floor_list);
    this.localstorage?.setItem('provider_shopping_id', info.location_provider?.provider_shopping_id);
    this.localstorage?.setItem('provider_label', info.location_provider?.provider_label);
    this.localstorage?.setItem('report_url', info.report_url);
    this.localstorage?.setItem('report_export_url', info.report_export_url);
  }
  saveShoppingCode(code: string){
    code = code.trim().toLowerCase();
    if(shoppingCodes.includes(code))
      this.localstorage?.setItem('shopping_code', code);
  }
  getAuthToken() {
    return this.localstorage?.getItem('token');
  }
  getUserRole() {
    return Number(this.getDecodedAccessToken()?.role ?? '-1');
  }
  getDecodedAccessToken(): any {
    try {
      if(!this.localstorage) return {};
      const token = this.localstorage?.getItem('token');
      if (token) return jwt_decode.jwtDecode(token);
      return null;
    } catch (Error) {
      this.logout();
      return null;
    }
  }
  sidenavToggle(sidenav: MatDrawer) {
    this.sidenav = sidenav;
  }
  public toggle(): void {
    this.sidenav.toggle();
  }
}
