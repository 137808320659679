export const defaultColors = {
    50: "#E5FFF6",
    100: "#C9FFEC",
    200: "#99DFC1",
    300: "#3AA06B",
    400: "#11562F",
    500: "#0A4020",
    600: "#032911",
    700: "#001D09",
    800: "#212529",
    900: "#000",
    suzano: "#371b10",
    catuai: "#bb111f"
}

