export const environment = {
  env: "dev",
  apiUrl: 'https://back-admin-dev.gmallsys.app/',
  // appQrCode: 'http://localhost:54384/',
  appQrCode: 'https://scan-dev.gmallsys.app/',
  urlLogin: '/auth',
  urlRefreshToken: '/refresh',
  urlResetPassword: '/reset-password',
  urlUser: '/user',
  urlFaq: '/faq',
  urlInfos: '/shopping_info',
  urlImage: '/image',
  urlBanner: '/banner',
  urlImageUpload: '/banner/image',
  urlBannerApprove: '/banner/status/',
  urlStore: '/store',
  urlShopkeeper: 'shopkeeper',
  urlStoreActivate: 'status/',
  urlImageUploadStore: '/store/image',
  urlEvent: '/event',
  urlEventImageUpload: '/event/image',
  urlEventApprove: '/event/status/',
  urlEventManagement: '/event_management',
  urlConsumeTicket: 'consume_ticket',
  urlOffer: '/offer',
  urlUpload: '/upload',
  urlGroupOffer: '/group-offer',
  urlOfferApprove: '/offer/status/',
  urlOfferActivate: 'status/',
  urlNotifications: '/notification',
  urlNotificatioActivate: '/status',
  urlUpdateLogo: '/logo/image',
  shoppingId: 2,
  varapidoUrl: 'https://varapido.com.br/sistema/app/',

};
