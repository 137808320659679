export const UserRoles = {
  admin: { id: 0, label: 'Administrador' },
  publisher: { id: 1, label: 'Publicador' },
  storeAdmin: { id: 2, label: 'Lojista' },
  promoter: { id: 3, label: 'Promotor' },
  criador: { id: 4, label: 'Criador' },
};

export const UserRoleId = [
  'Administrador',
  'Publicador',
  'Lojista',
  'Promotor',
  'Criador'
];
